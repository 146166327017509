
#maplayers{
    width: calc(100vw - 80px);
    height: calc(100vh - 60px);
    overflow: hidden;
    @media screen and(max-width: 993px) {
        width: 100vw;
    }

}
.hidden_estamonda{
    display:none !important;
}
.md-subheader .md-subheader-inner{
    background: lighten(#dadada, 8%);
}
.content_map {
    width: calc(100% - 64px);
    height: calc( 100vh - 60px);
    overflow: hidden;
    position: absolute;
    left: 64px;
    margin: 0;
    top: 60px;
    @media screen and(max-width: 950px) {
        left: 0;
        width: 100%;
    }
}

.center-flex {
    display: flex;
    // justify-content: center;
    align-items: center;
}

.md-inline-form md-checkbox {
    margin-right: 2em !important;
}

.homepage-hero-module {
    border-right: none;
    border-left: none;
    position: relative;
}

.no-video .video-container video,
.touch .video-container video {
    display: none;
}

.no-video .video-container .poster,
.touch .video-container .poster {
    display: block !important;
}

.video-container {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: #000;
    top: 0;
}

.video-container .poster img {
    width: 100%;
    bottom: 0;
    position: absolute;
}

.video-container .filter {
    z-index: 100;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
}

.video-container video {
    position: absolute;
    z-index: 0;
    bottom: 0;
}

.video-container video.fillWidth {
    width: 100%;
}

.first-element + td {
    display: none !important;
}

.mdl-card {
    display: flex;
    flex-direction: column;
    font-size: $card-font-size;
    font-weight: 400;
    min-height: $card-height;
    overflow: hidden;
    width: $card-width;
    z-index: $card-z-index;
    position: relative;
    background: $card-background-color;
    border-radius: 2px;
    box-sizing: border-box;
}

.mdl-card__media {
    background-color: $card-image-placeholder-color;
    background-repeat: repeat;
    background-position: 50% 50%;
    background-size: cover;
    background-origin: padding-box;
    background-attachment: scroll;
    box-sizing: border-box;
}

.mdl-card__title {
    align-items: center;
    color: $card-text-color;
    display: block;
    display: flex;
    justify-content: stretch;
    line-height: normal;
    padding: $card-vertical-padding $card-horizontal-padding;
    perspective-origin: $card-title-perspective-origin-x $card-title-perspective-origin-y;
    transform-origin: $card-title-transform-origin-x $card-title-transform-origin-y;
    box-sizing: border-box;
    &.mdl-card--border {
        border-bottom: 1px solid $card-border-color;
    }
}

.mdl-card__title-text {
    align-self: flex-end;
    color: inherit;
    display: block;
    display: flex;
    font-size: $card-title-font-size;
    font-weight: $card-title-text-font-weight;
    line-height: normal;
    overflow: hidden;
    transform-origin: $card-title-text-transform-origin-x $card-title-text-transform-origin-y;
    margin: 0;
}

.mdl-card__subtitle-text {
    font-size: $card-subtitle-font-size;
    color: $card-subtitle-color;
    margin: 0;
}

.mdl-card__supporting-text {
    color: $card-supporting-text-text-color;
    font-size: $card-supporting-text-font-size;
    line-height: $card-supporting-text-line-height;
    overflow: hidden;
    padding: $card-vertical-padding $card-horizontal-padding;
    width: 90%;
}

.mdl-card__actions {
    font-size: $card-actions-font-size;
    line-height: normal;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    padding: 8px;
    box-sizing: border-box;
    &.mdl-card--border {
        border-top: 1px solid $card-border-color;
    }
}

.mdl-card--expand {
    flex-grow: 1;
}

.mdl-card__menu {
    position: absolute;
    right: 16px;
    top: 16px;
}

// CUSTOM PROFILE
.profile-image {
    background-size: cover !important;
    background-position: center !important;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin: 0.8em 0;
    position: relative;
    left: 50%;
    top: 15px;
    transform: translateX(-50%);
}

.box-header-profile-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    label {
        margin-bottom: 0;
    }
    i {
        margin-top: -5px;
    }
}

.profile-name {
    color: white;
    margin-top: -1.1em;
    > h6 {
        font-size: 1rem;
        color: darken(white, 10%);
        margin-top: -.8em;
    }
}

.backgroud-profile {
    left: 0;
    right: 0;
    z-index: 500;
    background: url("/assets/images/background/1.png");
    background-size: cover;
    background-position: center;
}

// md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
//     min-height: 500px !important;
// }
.md-image-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0.7em 0.7em 0.7em -1.2em;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

// CUSTOM PANEL
.demo-md-panel {
    min-height: 500px;
}

.demo-dialog-example {
    background: white;
    border-radius: 4px;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
    width: 500px;
    max-height: 90vh;
    overflow: auto;
    z-index: 99999;
    position: fixed;
}

.demo-dialog-content {
    padding: 0 15px;
    width: 100%;
}

.demo-dialog-content img {
    height: 300px;
    margin: auto;
}

.demo-dialog-button {
    width: 100%;
}

.demo-menu-example {
    background: white;
    border-radius: 4px;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
    width: 256px;
}

.demo-menu-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 48px;
    padding: 0 16px;
    position: relative;
    transition: background 0.15s linear;
    width: auto;
}

.demo-menu-item:focus,
.demo-menu-item:hover {
    background-color: rgb(238, 238, 238);
}

.demo-menu-item.selected {
    color: rgb(16, 108, 200);
}

.custom-dialog {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    position: fixed;
    min-height: 90%;
    max-width: 80%;
    max-height: 90vh;
    width: 100%;
    height: 100vh;
    border-radius: 0;
}

.info-user md-list-item {
    height: 40px;
    min-height: 40px;
}

.circle-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 5px;
}

.geometry-container {
    padding: 1em;
    position: fixed;
    bottom: 5vh;
    height: calc( 100vh - 160px);
    max-height: calc( 100vh - 160px);
    overflow: hidden;
    width: 20%;
    right: -50%;
    margin: 0;
    transition: all 0.6s;
    .container {
        height: 100%;
    }
    .row {
        height: auto;
        .geometry {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        .type-geometry {
            .material-icons {
                //transition: all .8s;
                // color: $badge-color-inverse;
                margin-top: 5px;
                // color: $card-text-color;
                color: #dadada;
                font-size: 2em !important;
                height: 35px;
            }
        }
        .btn-new-geometry {
            width: 90%;
            margin-left: 5%;
            height: 40px;
        }
    }

    @media screen and (min-width:300px){
        width: 50%;
        // right: -54%;
    }

    // @media screen and (min-width:900px){
    //     width: 40%;
    //     right: 2em;
    // }

    // @media screen and (min-width:1000px){
    //     width: 40%;
    //     right: 2em;
    // }

    @media screen and (min-width:1300px){
        width: 30%;
        // right: 2em;
    }
}

.show_geometry_container {
    right: 2em;
}

div:focus {
    outline: none !important;
    border: 0 !important;
}

.selected_geometry {
    // animation: move_geometry .2s linear;
    animation-name: move_geometry;
    animation-duration: 0.8s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    /* or: normal */
    /* or: Xms */
    .material-icons {
        color: $badge-color-inverse !important;
        label {
            color: $badge-color-inverse !important;
        }
    }
}

.walkthrough-points {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 999999;
    background: rgba(0, 0, 0, .7);
    display: none;
    .tutorial-touch {
        position: absolute;
        left: 50%;
        top: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
        img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
        }
        .material-icons {
            color: $badge-color-inverse !important;
            // margin-left: -110px;
            // margin-top: 50px;
            margin-top: -50px;
            font-size: 5em;
        }
        p {
            text-align: center;
            color: white;
            font-size: 1em;
        }
    }
    .btn-ok {
        position: fixed;
        z-index: 99999;
        bottom: 5px;
        right: 5px;
    }
}

body {
    // overflow: hidden !important;
}

.selected_geometry_icon {
    color: $badge-color-inverse !important;
}

@-webkit-keyframes move_geometry {
    0% {
        transform: scale(1) rotateY(180deg);
    }
    25% {
        transform: scale(1.1);
    }
    75% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1.16) rotateY(0deg);
    }
}

.new_geometry_form {
    position: fixed;
    right: -30%;
    // top: 80px;
    width: 20%;
    height: auto;
    max-height: calc(100vh - 120px);
    transition: all 0.5s;
    padding: 1.5em 1.5em 4.5em;
    z-index: 9999;
}

.show_new_geometry {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-height: 50vh;
}

.control_routes {
    position: fixed;
    bottom: -70px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    width: auto;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em;
    transition: all 0.6s;
    max-width: 90%;
    div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .automatic-control {
        flex-direction: column;
    }
}

@media only screen and (max-width: 1024px) {
    // .geometry-container {
    //     width: 100%;
    //     top: initial;
    //     bottom: calc( -100vh - 160px) !important;
    //     //calc( -54vh - 160px) !important
    // }
    .new_geometry_form {
        right: -100%;
        width: 100%;
        max-height: calc(100vh - 60px) !important;
        height: calc(100vh - 60px);
        top: 60px;
        padding: 1.5em;
        margin: 0;
        overflow-y: scroll;
    }
    .show_new_geometry {
        right: 50%;
    }
    .filter_vehicles_map {
        width: 40%;
        right: -40%;
    }
}

.article-title {
    margin-bottom: 5px !important;
    padding-top: 0;
}

@media only screen and (max-width: 480px) {
    .profile-section {
        padding: 0 !important;
        margin-top: -30px;
    }
    .demo-dialog-example {
        z-index: 151;
        top: 0 !important;
        left: 0 !important;
        width: 100%;
        height: 100vh;
        transform: none !important;
        overflow-y: auto;
        max-height: 100vh;
        min-height: 100vh;
    }
    .profile-image {
        width: 80px;
        height: 80px;
    }
    .custom-dialog {
        max-width: 95%;
        max-height: 95vh;
        min-height: 95vh;
    }
    .info-user {
        margin-top: -8px;
        border-radius: 0;
    }
    .wizard-width {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }
    // .geometry-container {
        
    //     // bottom: -50vh !important;
    //     bottom: 0;
    //     height: 50vh !important;
    //     width: 100%;
    //     right: 0;
    // }
    .show_geometry_container {
        bottom: 0 !important;
        height: 45vh !important;
        width:100vw;
        left:0;
    }
    .container-fluid {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .table-points {
        height: calc(50vh - 35px) !important;
        overflow: auto !important;
    }
    .map_min {
        transition: all 0.5s;
        transition-timing-function: ease-in-out;
        height: calc(60vh - 60px) !important;
    }
    .filter_vehicles_map {
        width: 100% !important;
        right: -100% !important;
    }
}

.filter_vehicles_map {
    position: absolute;
    right: -30%;
    top: 0;
    height: calc(100vh - 60px);
    width: 30%;
    margin: 0;
    overflow: hidden;
    transition: all 0.4s;
    // overflow-y: auto;
    // padding: .5em;
    md-tab-content {
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(100vh - 108px);
    }
    .table.md-table td.md-cell {
        border-top: 0 solid black !important;
    }
    ul {
        list-style: none;
        padding: 0 0 0 1em;
        li {
            cursor: pointer !important;
        }
        .list_selecction_mobiles {
            display: flex;
            // justify-content: space-around;
            align-items: center;
            i {
                margin-right: 1em;
                margin-top: -9px;
            }
            &:first-child {
                margin-top: 1em;
            }
            md-checkbox {
                margin-bottom: 0;
                margin-top: -5px;
            }
        }
        // label {
        //     margin-top: 7px;
        //     margin-left: 4px;
        // }
        // .material-icons{
        //     font-size: 1.4rem;
        // }
    }
}

.show_filter_vehicles_map {
    right: 0 !important;
}

.li_estadio_mobiles {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.7em;
    flex-direction: row;
    .type_car_circle {
        padding: 15px;
        border-radius: 50%;
        background: #EF5350;
        color: white;
    }
    ul {
        margin-bottom: 0;
        font-size: 0.74rem;
    }
    li {
        display: flex;
        justify-content: flex-start;
        &:first-child {
            margin-top: 7px;
        }
    }
    i {
        margin-right: 1em;
    }
}

.battery_indicator {
    width: 10px;
    height: 85%;
    // border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    display: block;
    position: absolute;
    // transform:translateY(-50%);
    // left: 20px;
    bottom: 0;
    right: 0;
    .progress_battery {
        height: 50%;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #EF5350;
    }
}

.battery_indicator:after {
    content: '';
    position: absolute;
    width: 4px;
    height: 3px;
    left: 25%;
    transform: translateX(-50%);
    top: -3px;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}

.reporter_vehicle_ok {
    color: #66BB6A;
}

.reporter_vehicle_faile {
    color: #EF5350;
}

.table_mobiles {
    position: absolute;
    bottom: -40vh;
    left: 64px;
    height: 40vh;
    width: 100vw;
    margin: 0;
    transition: all 0.3s linear;
    table.md-table thead.md-head > tr.md-row {
        height: 40px;
    }
    table.md-table tbody.md-body > tr.md-row,
    table.md-table tfoot.md-foot > tr.md-row {
        height: 30px;
    }
    // thead{
    //     display: flex;
    // }
    // tbody{
    //     display:flex;
    //     height: 200px !important;
    //     overflow: auto !important;
    // }
}

.show_table_mobiles {
    bottom: 0;
}

.hp {
    display: flex;
    justify-content: center;
    align-items: center;
    // border:2px solid black;
}

.hp i {
    font-size: 2em !important;
    color: white;
}

.table_with_subtable td {
    padding-top: 15px;
    & > i,
    label {
        margin-top: 10px;
    }
    & > md-checkbox {
        // margin-top: -55px !important;
    }
}

md-select.md-table-select > .md-select-value > span {
    display: flex !important;
}

// #content_infowindow{
//   width: 500px;
// }
// .infowindow_image_driver{
//   border-radius: 50% !important;
//   width: 50px;
//   height: 50px;
// }
.content-infowindow {
    // padding: 0.05em;
    width: auto;
    min-width: 400px;
    overflow: hidden;
    max-width: 100vw;
    @media screen and (max-width: 450px) {
        font-size: 0.65rem;
    }
    .content_image {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .col-md-6 {
        padding: 0 !important;
    }
    .labels_infowindow {
        margin-top: 1em;
    }
    .fa {
        margin-right: 1em;
        font-size: 1.8rem;
        color: #66BB6A;
        margin-top: -5px;
    }
    .col-md-12 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // flex-direction: column;
        padding-bottom: 0.5em;
        .value_info {
            flex-direction: column;
            display: flex;
            align-items: flex-start;
        }
    }
    .img_info_window {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-image: url("http://static4.businessinsider.com/image/56c640526e97c625048b822a-480/donald-trump.jpg");
        background-size: cover;
        background-position: center;
    }
}

.map_popup_historic {
    position: fixed;
    left: 0;
    right: 0;
    // top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100vh;
    z-index: 10000;
    background: rgba(0, 0, 0, .8);
    display: none;
    md-slider-container {
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        bottom: -1em;
    }
    .md-track {
        background-color: white;
    }
    md-card {
        position: absolute;
        // top: 0;
        // left: 0;
        // right: 0;
        margin: auto;
        height: 100%;
        width: 100%;
        border-radius: 5px !important;
        overflow: hidden;
        #mapa_historico {
            width: 100%;
            height: 100%;
        }
    }
    .options_buttons {
        position: absolute;
        z-index: 90;
        top: inherit;
        left: 0;
        right: 0;
        bottom: 1em;
        // background: white;
        width: 300px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        ul {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 0 !important;
            margin-bottom: 0;
        }
        padding: 0;
        li {
            text-align: center;
            // margin-left: 5px;
            // margin-right: 5px;
            color: rgba(0, 0, 0, .8);
            display: flex;
            align-items: center;
            justify-content: space-around;
            i {
                font-size: 3em;
            }
            .fa {
                font-size: 1.8em;
            }
            .closed_simulator {
                position: fixed;
                right: 1em;
                top: 0.5em;
            }
        }
    }
}

.selection_plates {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: rgba(0, 0, 0, .85);
    width: 100%;
    height: 100%;
    z-index: 1001;
    md-card {
        width: 50%;
        left: 50%;
        top: 50%;
        position: absolute;
        height: 50vh;
        transform: translate(-50%, -50%);
        overflow: auto;
        @media screen and (max-width: 900px) {
            width: 90%;
            height: 70vh;
        }
    }
    .footer-custom-md-tab{
        position: absolute;
        bottom: calc(25vh - 74px);
        left: 50%;
        transform: translateX(-50%);
        width: 50%;
        height: 60px;
        margin: 8px;
        background: white;
        display: flex;
        padding: .5em;
        justify-content: flex-end;
    }
}

.filter_report {
    padding: 1em;
    ul {
        list-style: none;
        ;
    }
}

.alarm-container {
    position: relative;
    .new_notifications {
        width: 150px;
        height: 30px;
        background: #EF5350;
        position: fixed;
        z-index: 100;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 8px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        label {
            text-align: center;
            color: white;
            margin-bottom: 0;
        }
    }
}

.container-porcent {
    width: 100%;
    height: 20px;
    // background: red;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, .8);
    margin-bottom: 5px;
    overflow: hidden;
    max-width: 100px;
    .porcent {
        height: 100%;
        // width: 20%;
        background: red;
    }
}

.custom_info_window {
    position: fixed;
    z-index: 1001;
    bottom: 0;
    top: auto;
    margin: 0;
    border-radius: 0;
    // height: 40vh;
    left: 75%;
    // padding:2em;
    width: calc(100% - 64px);
    transition: all .5s ease;
    .velocidad_content {
        // display: none;
    }
    .column_infowindow > div {
        display: flex;
        flex-direction: column;
    }
    .material-icons {
        font-size: 2.2rem !important;
    }
    .icon-box {
        margin-top: 10px !important;
    }
    .icon-box p {
        margin: 0 !important;
        font-size: 12px;
    }
    .icon-box h3 {
        margin-top: -7px;
        font-size: 14px;
    }
    md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
        min-height: 320px;
    }
    md-tabs[md-border-bottom] md-tabs-wrapper {
        border-style: none;
    }
    md-tab-content {
        overflow-y: auto;
        overflow-x: hidden;
        height: 85%;
    }
    .info_vehicle_info_window {
        position: relative;
    }
    .full_screen {
        position: absolute;
        top: 65%;
        left: 25px;
        z-index: 100;
    }
    .full_screen_mobile {
        position: absolute;
        top: .5em;
        left: 1.9em;
        z-index: 100;
    }
    .close_infowindow {
        position: absolute;
        top: .5em;
        left: 1em;
        z-index: 100;
    }
    .ibox-center {
        margin-top: 4.3em !important;
    }
    md-tabs-content-wrapper {
        height: 100%;
    }
    @media screen and (max-width:1250px) {
        .icon-box h3 {
            margin: 0 !important;
            font-size: 10px !important;
        }
    }
    @media screen and (max-width:950px) {
        left: 0 !important;
        width: 100%;
        height: 260px;
        bottom: 0;
        height: 100vh !important;
        .info_vehicle_info_window {
            // height: 32vh;
            position: relative;
            margin-bottom: 20px;
            padding-left:1em;
            padding-right:1em;
        }
        .acciones {
            height: auto;

            md-tabs {
                min-height: 80vh !important;
            }
        }
        .velocidad_content {
            
        }
        .ibox-center {
            margin-top: 1em !important;
        }
        .full_screen_mobile {
            left: auto;
            right: 2.5em;
        }
        .close_infowindow {
            left: auto;
            right: .8em;
        }
        .velocimetro {
            display: none;
        }

        md-tab-content{
            margin-bottom: 8em;
        }
    }
}

.expand_infowindow {
    // animation: mymove 2s ;
    left: 0;
    width: 100%;
   
    @media screen and (max-width: 950px) {
        //animation: mymove 2s linear infinite;
        height: calc(100vh - 60px);
        top: 60px;
        z-index: 99999;
    }
}

@keyframes mymove {
    50% {
        top: 60px;
    }
    100% {
        height: calc(100vh - 60px);
    }
}

.cell-options {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 10px;
    margin-top: 12px;
}

.content-icon-horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-top: 12px;
}

.content-icon:nth-child(1) {
    margin-left: -40px;
}

.content-icon-toolbar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    label {
        font-size: .8rem;
        margin-top: -5px;
    }
}

.custom_color_alarm {
    background: rgb(239, 83, 80) !important;
    color: white !important;
}







.table_alarms{
    margin-top: 10px;
    position: absolute;
    bottom:0;
    height: calc(100vh - 60px);
    background-color: #fff; 
    overflow: hidden;
    left: 50px;
    max-width:calc(100vw - 50px);


    // table.md-table td.md-cell{
    //     color: white !important;
    // }
    @media screen and (max-width:990px){
        left:0 !important;
        max-width: 100vw;
    }


    
}

.btn-closed-alarms{
    position: absolute;
    right: 1.2em;
    top: 13px;
    z-index: 100;   
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer;
}







.content_profile{
    width: 100%;
    height: auto;
    // background: red; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    .profile_picture{
        flex:1 1;
        width: 100px !important;
        height: 100px !important;
        border-radius: 50%;
        border:5px solid rgb(33, 150, 243);
        margin-top: 2em;
        background-image: url(/assets/images/user.png);
        background-size: cover;
        background-position: center;
    }

    .name_user{
        font-size: 1.2rem;
        font-weight: 500;
        display: flex;
        align-items:center;
        justify-content: space-around;

        i{
            margin-right: 1em;
        }
    }


    .div_conetnt{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    
    
      
}
.actions_profile{
    .ibox-light{
        display: flex;
        flex-direction: column;
    } 
}
.content-image{
    width:150px; 
    height:150px;
    display: none;
    border-radius:50%;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &:hover .content_delete{
        left: 0;
        transform: rotate(-360deg);
    }
    .content_delete{
        transition: all .5s;
        
        position: absolute;
        left: 100%;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: rgba(0, 0, 0, .8);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-weight: 500;
        color:white;
        label,i{
            cursor: pointer;
        }
    }
}
#img_contact{
    position:relative;border-radius:50%;  border:1px solid #dadada;
    height: 100%; width: 100%;
    object-fit: cover;
}



.journey{
    transition: all .5s;
    position: relative;
    &::after{
        content: '';
        left: 0;
        top: 0;
        position: absolute;
        height: 90%;
        width: 5px;
        background: #00BCD4;
    }

    &::before{
        content: '';
        left: -3px;
        bottom: calc(10% - 10px);
        position: absolute;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 10px solid #00BCD4;
    }
    &:last-child:after,&:last-child:before{
        height: 0;
        display: none;
    }
}
.content-journey{
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
     padding: 1em;
     position: relative;
     min-height: 130px;
     .index-journey{
         position: absolute;
         width: 20px;
         height: 20px;
         display: flex;
         justify-content: center;
         align-items: center;
         color: white;
         background: #00BCD4;
         left: -22px;
         top: -5px;
         z-index: 100000;
         border-radius: 50%;
     }
     
}
.options-journey{
         position: absolute;
         right: .5em;
         top: .5em;
         width:auto;
         height: auto;
         padding: .2em;
         display: flex;
         justify-content: space-around;
         align-items: center;
     }
.setAnimation{
    box-shadow: 0 1px 13px 0 #EF5350, 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 11px -1px #EF5350;
}


label.error{
    color: #EF5350;
}


.drag_space{
    
    margin: auto;
    margin-top: 3em;
    z-index: 10000;
    width: 500px;
    height: 500px;
    padding: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
    border: 5px dashed #dadada;
    transition: all .5s;
    @media screen and (max-width:1400px){
        width: 300px;
        height: 300px;
        & i{
            
            font-size: 8em !important;
        }
        & h4{
            font-size: 1.1em;
        }
    }
    & i{
        
        font-size: 12em;
    }
    & h4{
        text-align: center;
    }
}

.hoverfile .drag_space{
    transform: scale(1.05);
    background: rgb(33,150,243);
    border-color: white;
    color:white;
    
}


.bluesky{
    

    // .col-md-4:nth-child(1),.col-md-4:nth-child(2),.col-md-4:nth-child(3){
    //     height: calc(47.5vh - 1em);
    //     margin-bottom: 1em;
    //     .box{
    //         height: calc(47.5vh - 1em);
    //     }
    // }
    // .col-md-4:nth-child(4),.col-md-4:nth-child(5),.col-md-4:nth-child(6){
    //     height: calc(47.5vh - 1em);
    //     .box{
    //         height: calc(47.5vh - 1em);
    //     }
    // }

    .col-md-4,.box{
        height: calc(100vh - 5.4em);
    }
}


.uploadContent{
    display: flex;
    justify-content: space-around;
    align-items: center;
    
    i{
        font-size:15em;
    }
}

.tl-content{
    overflow-x:auto;
}
.chips-dashboard md-chip{
    font-size: .75rem !important;
}
// #bluesky .col-md-4{
//     height: 100vh !important;
// }



.label-centro-control{
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 40px;
    height: 20px;
    transition: all .2s;
    i{
        font-size: 1rem;
    }

    &:hover{
        transform: scale(1.1);
    }
}



.alarmas_container{
    position: fixed;
    bottom: -100vh;
    left: 0;
    width: 100vw;
    height: calc(100vh - 60px);
    background: white;
    z-index: 101;
    overflow: auto;
    transition: all .5s;
    tr,td{
        //color:white !important;
    }

    table.md-table tbody.md-body > tr.md-row, table.md-table tfoot.md-foot > tr.md-row{
        height: 31px !important;
    }

    table.md-table td.md-cell{
        font-size: 12px !important;
        text-rendering: optimizeLegibility;
    }
}

.white-color-td{
    color:white !important;

    md-icon{
        color:white !important;
    }
}

.black-color-td{
    color: lighten(black,20%) !important;

    md-icon{
        color: lighten(black,20%) !important;
    }
}


.btn-desactivar-alarm{
    min-height: 21px !important;
    font-size: .8rem;
    line-height: 21px !important;
    margin-left:15px;
}



#cardAlarmId{
    top:110px;left:75px;max-height: 40vh; overflow:auto;position: fixed; z-index: 10; width:350px;max-width:calc(100vw - 75px);

    @media screen and (max-width:900px){
        width: 100vw;
        max-width:100vw;
        left: 0;
        bottom: 0 !important;
        height:40vh;
        top:auto;
        position:fixed;
        .panel-body .col-md-12{
            display:flex;
            flex-wrap:wrap;
            
            div{
                width:50%;
            }
        }
    }
}


table.md-table:not(.md-row-select) td.md-cell:first-child, table.md-table:not(.md-row-select) th.md-column:first-child{
    padding: 15px 27px !important;
}


.cell-vehicle{
    border-right: 1px solid  rgba(0, 0, 0, 0.12) ;
    font-weight: 600;
}



.velocimetro{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    canvas{
        margin-left: 40px !important;
    }
    p{
        margin-top: 35px;
        text-align: center;
        margin-bottom: -30px;
    }
}


md-checkbox.md-default-theme[disabled]:not(.md-checked) .md-icon, md-checkbox[disabled]:not(.md-checked) .md-icon{
    display: none !important;
}

.progress-simulator{
    width: 100%;
    height: 8px;
    position: absolute;
    bottom: 0;

    .progress-indicator{
        background: rgb(33,150,243);
        height: 100%;
        width:0;
    }
}


.tydenbrooks{

    .back_to_login{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: rgba(255,255,255, .2);
        position: fixed;
        right: 2em;
        bottom: 1em;
        z-index: 100000;
        display: none;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        i{
            color:rgba(0,0,0,.3);
        }
    }
    @media screen and (max-width : 480px) {
        .back_to_login{
            display: flex;
        }
    }
    
    @media screen and (max-width : 480px) {
        
        .title_tydenbrooks{
            font-size: 2em !important;
            text-align: center !important;
        }


        .logo_tydenbrooks{
            max-width: 70% !important;
        }

        .logo_geotech{
            max-width: 50% !important;
        }
    }
}

.list_login_tydenbrooks{

    list-style: none; padding: 0;

    li{
        color: white; font-size: 1.5rem; margin-bottom:15px; display: flex;
    }

    img{
        margin-right:2em;margin-left:4em;
    }


    @media screen and (max-width : 480px) {
        .title_tydenbrooks{
            font-size: 1.5em;
            text-align: center;
        }
        .div_image{
            min-width: 20px;
            max-width: 20px;
            flex:1;
        }
        img{
            margin: 0;
        }
    }
}