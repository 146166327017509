.mdl-badge {
    position: relative;
    white-space: nowrap;
    margin-right: ($badge-size + $badge-padding);

    &:not([data-badge]) {
        margin-right: auto;
    }

    &[data-badge]:after {
        content: attr(data-badge);

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        align-items: center;

        position: absolute;
        top: -($badge-size / 2);
        right: -($badge-size + $badge-padding);

        .mdl-button & {
            top: -10px;
            right: -5px;
        }

        font-family: $preferred_font;
        font-weight: 600;
        font-size: $badge-font-size;
        width: $badge-size;
        height: $badge-size;
        border-radius: 50%;

        background: $badge-background;
        color: $badge-color;
    }

    &.mdl-badge--no-background {
        &[data-badge]:after {
            color: $badge-color-inverse;
            background: $badge-background-inverse;

            box-shadow: 0 0 1px gray;
        }
    }
    &.mdl-badge--overlap {
        margin-right: ($badge-size - $badge-overlap);
        &:after {
            right: -($badge-size - $badge-overlap);
        }
    }
}


.disabled_div {
    position: relative;
    transition: all .5s;
    &:after {
        -webkit-transition: all .5s;
        /* For Safari 3.1 to 6.0 */
        transition: all .5s;
        z-index: 1050;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(#ddd, 0.4);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

summary {
    outline: none;
    font-size: 1.1em;
    font-weight: 500;
}

summary::-webkit-details-marker {
    display: none
}

summary:after {
    color: rgba(0, 0, 0, 0.54);
    border-radius: 5px;
    content: "+"; // color: #fff; 
    float: left;
    font-size: 2em;
    font-weight: bold;
    margin: -5px 10px 0 0;
    padding: 0;
    text-align: center;
    width: 20px;
    cursor: pointer;
}

details[open] summary:after {
    content: "-";
}


.toolbartables {
    left: 0;
    top: 0;
    z-index: 1000;
    height: 60px;
    min-height: 60px !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    @media only screen and (max-width: 480px) {
        position: fixed;
    }

    /* Small Devices, Tablets */
    @media only screen and (max-width: 768px) {
        position: fixed;
    }
}

.header-report {
    display: none;
}

@media screen and(max-width:900px) {
    .header-report {
        width: 100vw;
        height: 60px;
        background: #2196F3;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        padding-left: 1em;
        i {
            font-size: 2.2rem !important;
            color: white;
        }
        h3 {
            color: white;
            font-size: 1.5rem;
            margin-left: 1rem;
        }
    }
    #form-reportes {
        margin-top: 50px;
    }
}




.walkthrough {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 99999999999999999;
    background: rgba(black, .58);
    animation: walkthrough 1s ease-in-out;


    .item-walkthrough {
        width: 50vw;
        height: 80vh;
        max-height: 100vh;
        max-width: 100vw;
        background: white;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: .5em;
        overflow: hidden;
        padding: 0;
        border: none;
        animation: itemwalkthrough 3s linear;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transition: all .5s !important;
        @media screen and (max-width: 768px) {
            width: 100%;
            height: 100%;
        }

        &:nth-child(6) {
            background-image: url("/assets/images/tutorial/1.png");
            @media screen and (max-width: 768px) {
                background-image:url("/assets/images/tutorial/movil/1.png");
                background-size: 100% 100%;
            }
        }
        &:nth-child(5) {
            background-image: url("/assets/images/tutorial/2.png");
            @media screen and (max-width: 768px) {
                background-image:url("/assets/images/tutorial/movil/2.png");
                background-size: 100% 100%;
            }
        }
        &:nth-child(4) {
            background-image: url("/assets/images/tutorial/3.png");
            @media screen and (max-width: 768px) {
                background-image:url("/assets/images/tutorial/movil/3.png");
                background-size: 100% 100%;
            }
        }
        &:nth-child(3) {
            background-image: url("/assets/images/tutorial/4.png");
            @media screen and (max-width: 768px) {
                background-image:url("/assets/images/tutorial/movil/4.png");
                background-size: 100% 100%;
            }
        }
        &:nth-child(2) {
            background-image: url("/assets/images/tutorial/5.png");
            @media screen and (max-width: 768px) {
                background-image:url("/assets/images/tutorial/movil/5.png");
                background-size: 100% 100%;
            }
        }
        &:nth-child(1) {
            background-image: url("/assets/images/tutorial/6.png");
            @media screen and (max-width: 768px) {
                background-image:url("/assets/images/tutorial/movil/6.png");
                background-size: 100% 100%;
            }
            .next-item {
                display: none;
            }
        }




        a {
            cursor: pointer;
        }

        .back-item,
        .next-item {
            position: absolute;
            width: 50px;
            height: 50px;
            opacity: 0.8;
            bottom: 1.5em;
        }
        .back-item {
            left: 2.2em;
        }
        .next-item {
            right: 2.2em;
        }

        .finish-tutorial {
            position: absolute;
            left: 50%;
            bottom: 3.6em;

            width: 240px;
            height: 190px;

            left: 50%;
            transform: translateX(-44%);
        }
    }
}


@keyframes textAniamtion {
    0% {
        bottom: -1em;
    }

    100% {
        bottom: 3.4em;
    }
}

@keyframes logoAnimation {
    0% {
        top: -1em;
    }
    100% {
        top: 50%;
    }
}


@keyframes walkthrough {
    0% {
        width: 0;
        height: 0;
    }

    100% {
        width: 100%;
        height: 100%;
    }
}

@keyframes imgwalkthrough {
    50% {
        transform: rotate(360deg);
    }
    100% {
        transform: scale(1.4)
    }
}



.controls-geometry{
    position:fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom:2em;
    width: auto;
        min-width: 30vw;
    height: auto;
    background: #2196F3;
    border-radius:5px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    display: none;
    max-width: 100vw;
    .content-buttton-controls{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        padding:5px;

        i,label{
            color: white;
        }
    }
}


.options-panel-tracking{
   display: flex;
   align-items: center;
   justify-content:flex-end;
}


.show_help{
    position: fixed;
    width:100%;
    height: calc(100vh - 60px);
    left: 0;
    top: 60px;
    background: rgba(0,0,0,.8);
    z-index: 100;


    .option-help{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        max-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            max-height: 90vh;
        }
    }
}


.help_right{
    position: fixed;
    width:400px;
    right: 0;
    top: 60px;
    height: calc(100vh - 60px);
    background: white;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    overflow: auto;
    right: -400px;
    transition: all .5s;
    z-index: 10000;
    img{
        width: 100%;
        object-fit: cover;
    }
}

.show_help_right{
    right: 0;
}
.small-content-page{
    width:calc(100% - 400px);
}

.shadow_new{
    -webkit-box-shadow: 0 2px 12px 0 #dfe3eb;
    box-shadow: 0 2px 12px 0 #dfe3eb;
    background: #ffffff;
    border-radius: 5px;
    overflow: hidden;
}

.map_dashboard{
    height: 350px;

    padding: 0;
    
}

.address_movile p{
    padding: 1em;
}




.show_info_movil{
    bottom: 0 !important;
}
.new_info_window{
    transition: all .5s;
    position: fixed;
    right: 0;
    bottom: -100vh;
    width: 100vw;
    background: white;
    height: 100vh;
    z-index: 1001;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    // padding: 1em;
    overflow: auto;

    .header_info_mobile{
        display: flex;
        align-items: center;
        padding-left: 1em;
    }

    .button_dashboard_movile{
        display: flex;
        align-items: center;
        i,span{
            color: white;
            margin-right: 5px;
            font-size: 1.5em;
        }
    }
    .header_info_mobile{
        height: 60px;
        width: 100%;
        background: #2196F3;
        margin-bottom: 3px;
    }
    .map_container_movile{
        height: 150px;
        width: 100%;
        background: url(https://media.wired.com/photos/59269cd37034dc5f91bec0f1/2:1/w_2500,c_limit/GoogleMapTA.jpg);
        background-position: center;
        border:1px solid rgba(black,.1);
    }


    
    .info_movil{
        display: flex;
        // justify-content: space-between;
        padding: 15px 1em 15px 1em;
        align-items: center;
        position: relative;
        
        .plate{
            font-weight: bold !important;
        }
       

        .info_movil_icon{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .circle_icon{
            background: #2196F3;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
           
        }

        i{
            font-size: 2em;
            color: white;
        }
        p{
            margin: 0 !important;
            font-size: .9rem;
            margin-left: 1em;
            color: darken(#dadada,25%) !important;
        }
    }


    .status_mobile{
        display: flex;
        padding: .5em;
        // justify-content: space-around;  
        .icon_content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            margin-right: 18px;
            span{
                color: darken(#dadada,25%);
                font-size: .9rem;
            }
        }
    }
}



.confirm_login{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(black, .58);
    z-index: 1000;
}
.vFlotante {
    background: #1E5670;
    z-index: 500;
    position: absolute;
    right: 40px;
    top: 60px;
    border-radius: 25px;
    border: 2px solid #40A4C0;
    padding: 20px;
    width: 300px;
    height: 350px;
    font-size: 9pt;
    color: #FFFFFF;
}

.h1olv {
    color: #FFFFFF;
    font-size: 15pt;
}

.polv {
    color: #FFFFFF;
    font-size: 10pt;
    align: justify;
}

.aolv {
    float: right;
    color: #FFFFFF;
    text-align: right;
    align: right;
}

.aolv:hover {
    color: #E2E2E2;
}


.newLogin {
    z-index: 10000000000;
    display: table;
    margin: 0 auto;
    height: 100%;
    width: 800px;
    position: relative;

}

.newLogin .content {
    height: 600px;
    width: 800px;
}
.newLogin .main-content {
    height: 100%;
    display: table-cell;   
    vertical-align: middle;    
    height: 600px;
    width: 800px;


    background-image: url('/assets/images/banner-geosmart.png') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}
.newLogin .main-content  .content .header .center center .logo-beta{ 
    width: 200px;
}
.newLogin .main-content  .content .header .center center .logo-smart{ 
    width: 270px;
}
.newLogin .geosmart-font {
    font-family: 'Montserrat'; 
    font-size: 22px; 
    color:#555555;
    line-height: 40px;
    letter-spacing: 1px;
}
.newLogin .main-content  .content .header .center center span {
    color:#555555;
    
}
.newLogin .main-content  .content .header .center center div span {
    color:#555555;
}
.newLogin .header {
    position: relative;
    height: 550px;
}

.newLogin .header .gradient {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(255, 255, 255, .1), rgba(0, 0, 0, .5), rgba(0, 0, 0, .7));
    z-index: 101;
}



.newLogin .info-login {
    position: absolute;
    left: 0;
    height: auto;
    display: flex;
    flex-direction: column;
    z-index: 102;
    top: 50%;
    transform: translateY(-60%);
    padding-left: 50px;
}

.newLogin .info-login img {
    width: 250px;
    margin-left: -16px;
}

.newLogin .info-login p {
    color: white;
    text-transform: uppercase;
    font-size: 1em;
    margin: 0;
    text-shadow: 1px 1px 1px black;
}

.newLogin .info-login p .weight {
    font-weight: 700;
}

.newLogin .info-login button {
    background: white;
    width: 200px;
    height: 50px;
    border-radius: 10px;
    color: #226788;
    text-transform: uppercase;
    box-shadow: inset -6px -33px 37px -26px rgba(46, 42, 46, 0.45);
}


.button-one {
    margin-right: 9%;
    width: 190px;
    background-color: #01caff;
    border-radius: 18px;
    padding: 5px;
    color: white;
    font-size:  15px !important;
}

.button-two {
    font-family: 'Montserrat'; 
    margin-left: 9%;
    width: 190px;
    background-color: #006789;
    border-radius: 18px;
    padding: 5px;
    color: white;
    font-size: 15px !important;
}

.newLogin .footer-new-login {
    font-family: 'Montserrat'; 
    font-size: 15px; 
    height: 50px; 
    background: #006789;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 2em;
}

.newLogin .footer-new-login span {
    font-size: 1.2rem;
    color: white;
}

.newLogin .footer-new-login span span {
    color: #01caff;
    font-weight: 600;
    cursor: pointer;
}

.bottom-text {
    margin-top: 20px;
}
.novedades {
    margin-top: 20px;
    justify-content: center;
    center {
        height: 50px;
    }
}
@media screen and (max-width:900px) {
    .newLogin {
        width: auto !important;
        background: black;
    }
    .button-one {
        margin-left: 0 !important;
        margin-right: 0 !important;
        background-color: #01caff;
        border-radius: 4px;
        padding: 1px;
        color: white;
        font-size: 1em !important;
        width: 50%;
    }

    .button-two {
        margin-left: 0 !important;
        margin-right: 0 !important;
        background-color: #006789;
        border-radius: 4px;
        padding: 1px;
        color: white;
        font-size: 1em !important;
        width: 50%;
    }
    .newLogin  .main-content  .content .header .center center .logo-beta{ 
        width: 120px;
        
    }
    .newLogin  .main-content  .content .header .center center {
        margin-top: 5px !important;
    }
    .newLogin  .main-content .content .header .center center .logo-smart{ 
        width: 180px;
    }
    .newLogin .main-content  .content .header .center center span {
        font-family: Arial, Helvetica, sans-serif; 
        font-size: 1.5em; 
        color:#555555;
        line-height: 0.9;
    }
    .newLogin .main-content  .content .header .center center div span {
        font-family: Arial, Helvetica, sans-serif; 
        font-size: 1.2em; 
        color:#555555;
        line-height: 0.9;
    }
    .newLogin .main-content  {
        left: 0;
        width: 100%;
        background-repeat: repeat !important;
    }
    .newLogin .content {
        width: auto !important;
        height: auto !important;
    }
    .newLogin .header {
        height: auto !important;
    }


    .newLogin .info-login img {
        width: 200px;
        margin-left: -10px;
    }

    .newLogin .info-login p {
        font-size: .95em;
    }

    .newLogin .info-login button {
        margin-top: 10px;
    }

    .novedades {
        justify-content: center;
        height: 10vh;
    }
    .novedades .novedad {
        width: 50%;
    }
    .novedades .novedad img {
        width: 40px;
        height: 40px;
    }

    .novedades .novedad h3 {
        font-size: 1em;
    }

    .novedades .novedad p {
        width: 100%;
        margin-left: 0;
    }

    .newLogin .info-login {
        transform: translateY(-50%);
    }

    .newLogin .footer-new-login {
        justify-content: center;
        text-align: center;
        width: 100%;
        bottom: 20px;
        position: fixed;
    }
    .newLogin .footer-new-login span {
        font-size: 0.9rem !important;
    }
    .bottom-text {
        height: auto;
       
        margin-top: 60px;
    }
    .bottom-text span {
        font-size: 1em;
    }
}



.info_dashboard_mobile{
    padding: 1em;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    
}

.info_dashboard_mobile.distance{
    background: #ffc603;
    span{
        color: white;
        b{
            font-size: 1.1rem;
        }
    }
}
.info_dashboard_mobile.time{
    background: #4980ff;
    span{
        color: white;
        b{
            font-size: 1.1rem;
        }
    }
}
.info_dashboard_mobile.ralenti{
    background: #47c89f;
    span{
        color: white;
        b{
            font-size: 1.1rem;
        }
    }
}





.selected_filter{
    position: relative;
    &::after{
        content: "";
        position: absolute;
        left: 0;
        bottom: -15px;
        height: 4px;
        width: 100%;
        background: #2196F3;
    }
}


.tydenbrooks{
    padding-left: 10em;
    padding-right: 10em;

    @media only screen and (max-width : 767px) {
        padding-left: 4em;
        padding-right: 4em;
    }
    
}


.container_messages{
    display:block !important;
    background: rgb(229, 221, 213) !important;
    
    position: relative;
   
    .messages{
        width: 100%;
        height: calc(100% - 60px);
        position: absolute;
        top: 0;
        left: 0;
        overflow: auto;
        padding: 10px 2em;
        display: flex;
        flex-direction: column;
    }
    .input_message , .output_message{
        border-radius: 5px;
        margin-bottom: 20px;
        padding: .5em;
        max-width: 100% !important;
        border-radius: 7.5px;
        box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
        display: flex;
        align-items: center;

        p{
            margin: 0 !important;
        }
    }

    .input_message{
        background: rgb(255, 255, 255);
        float: left;
    }

    .output_message{
        background:rgb(220, 248, 198);
        float: right;
        align-items: center;
        justify-content: space-between;
    }

    .container_input{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background-color: rgba(255, 255, 255, 0.6);
        padding: 9px 8px 11px 12px;
        display:flex;
        align-items: center;
        justify-content: space-around;

        button{
            background: transparent;
        }

        input{
            border-radius: 15px;
        }
    }
}






