
.article-title {
    font-size: 24px;
    font-weight: normal;
    line-height: 32px;
    margin: .6em 0;
    color: $brand-primary;
    &.color-dark {
        color: $text-color;
    }
}

//
.chapter {
    .article {
        padding-bottom: 30px;
        &:nth-of-type(1) {
            .article-title {
                margin: 0 0 30px;
                border-top: none;
            }
        }

        &.article-dark {
            background-color: rgba(0,0,0,.015);
        }

        &.article-bordered {
            border-top: 1px solid rgba(0, 0, 0, 0.117647);
            border-bottom: 1px solid rgba(0, 0, 0, 0.117647);

            &:last-child {
                border-bottom: 0;
            }
        }

        &.padding-lg-v {
            padding: 75px 0;
        }
    }

    .article-title {
        font-size: 24px;
        padding-top: 30px;
        font-weight: normal;
        line-height: 32px;
        margin: 48px 0 30px;
        border-top: 1px solid rgba(0, 0, 0, 0.117647);
        color: $brand-primary;
    }
}

// Hero
// ------------------------------------
.hero {
    padding: 70px 0 75px;
    text-align: center;
}
.hero-title{
    -webkit-font-smoothing: antialiased;
}
.hero-title {
    color: $text-color;
    font-size: 45px;
    font-weight: $font-weight-thin;
    line-height: 50px;
    margin-bottom: 10px;
}
.hero-tagline {
    margin: 10px auto 30px;
    max-width: 700px;
    color: $text-color;
    font-weight: $font-weight-thin;
    font-size: 24px;
    line-height: 32px;
}

// Dark theme
// ------------------------------------
.theme-gray,
.theme-dark {
    .hero-title,
    .hero-tagline {
        color: $theme_dark_text_color;
    }

    .article-title {
        &.color-dark {
            color: $theme_dark_text_color;
        }
    }
}
